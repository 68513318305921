import "../styles/AboutMe.scss";

import csImage from "../images/icons/cs.png";
import davisImage from "../images/icons/davis.jpg";

import emailSVG from "../images/icons/email.svg";
import pinSVG from "../images/icons/pin.svg";
import downloadIcon from "../images/icons/download.png";

import React from "react";

function AboutMe() {

    const [codingTimeString, setCodingTimeString] = React.useState("Loading...")

    React.useEffect(() => {
        setInterval(() => {
            setCodingTimeString(TimeSince("2017-09-17"));
        }, 1000);
    }, []);

    function CalculateAge(date : string) : number {
        let today = new Date();
        let birthDate = new Date(date);
        let age = today.getFullYear() - birthDate.getFullYear();
        let month = today.getMonth() - birthDate.getMonth();

        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) age--;

        return age;
    }

    function TimeSince(date : string) {
        let today = new Date();
        let startDate = new Date(date);

        // Get total number of seconds between the times.
        let delta = Math.abs(today.getTime() - startDate.getTime()) / 1000;

        // Calculate (and subtract) whole days
        let days = Math.floor(delta / 86400);
        delta -= days * 86400;

        // Calculate (and subtract) whole hours
        let hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;

        // Calculate (and subtract) whole minutes
        let minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;

        // What's left is seconds
        let seconds = Math.floor(delta % 60);

        return `${days} days, ${hours} hours, ${minutes} minutes, and ${seconds} seconds`;
    }

    return (
        <div className={"AboutMe"}>
            <div className={"Card"} id={"about-me"}>
                <div className={"DescriptionDiv"}>
                    <h2>About Me</h2>
                    <p>
                        My name is <span className={"DescriptionBold"}>William Orlopp</span>,
                        a <span className={"Bold Description"}>{CalculateAge("2003-02-01")}</span> year old
                        <span className={"Emphasize Description"}> Computer Science</span> student.
                        For now, I have been working on personal passion
                        projects, amassing my coding knowledge in the hopes
                        of pursuing a CS career. The total time I have spent
                        working and studying CS is...
                    </p>
                    <span className={"Highlight Description"}>{codingTimeString}</span>
                </div>
                <img src={csImage} alt={"cs logo"} id={"about-me-image"}/>
            </div>

            <div className={"Card"} id={"education"}>
                <div className={"DescriptionDiv"}>
                    <h2>Education</h2>
                    <p>
                        <span className={"DescriptionBold"}>University of California, Davis</span> - <span className={"Emphasize Description"}>Computer Science</span>, Major GPA: 3.6
                    </p>
                    <p>September 2021 - Present</p>
                    <p>
                        <span className={"Description Emphasize"}>Expected</span> Graduation - June 2023
                    </p>
                    <p className={"Description Emphasize"}>University of California, Davis is highly ranked for <span className={"DescriptionBold"}>Undergraduate Computer Science Programs</span>.</p>
                </div>
                <img src={davisImage} alt={"davis logo"} id={"davis-image"}/>
            </div>

            <div className={"Card"} id={"coding-languages"}>
                <div className={"DescriptionDiv"}>
                    <h2>Coding Languages</h2>
                    <p>
                        This is a graph containing my main list of <span className={"Bold Description"}>coding languages</span>.
                    </p>
                </div>
                <div id="wakatime-graph">
                    <figure>
                        <embed src="https://wakatime.com/share/@beanbeanjuice/6ce816e9-122c-4e18-be66-492dabc8841c.svg"/>
                    </figure>
                </div>
            </div>

            <div className={"Card"} id={"motivation"}>
                <div className={"DescriptionDiv"}>
                    <h2>Motivation</h2>
                    <p>
                        I just simply love <span className={"DescriptionBold"}>coding</span>! 😀 I love finding something that needs to
                        be solved and creating a solution. Despite it being tedious, I also love to debug and find all the
                        "invisible" edge cases.
                    </p>
                </div>

                <div className={"Wakatime"}>
                    <h1>Time Coding Since May 19, 2021</h1>
                    <img src="https://wakatime.com/badge/user/beeb4317-977b-4b19-878a-21e9aa8e43ed.svg?style=for-the-badge" alt="Total time coded since May 29 2021" />
                </div>
            </div>

            <div className={"Card"} id={"contact-card"}>
                <h2>Contact</h2>
                <div>
                    <div>
                        <img src={emailSVG} alt="email icon"/>
                        <h2>Email</h2>
                        <a href="mailto: williamorlopp@outlook.com">williamorlopp@outlook.com</a>
                    </div>

                    <div>
                        <img src={pinSVG} alt="map pin icon"/>
                        <h2>Location</h2>
                        <p>Davis, CA</p>
                    </div>

                    <div>
                        <img src={downloadIcon} alt="download icon"/>
                        <h2>Resume</h2>
                        <a href="https://cdn.orloppw.com/website/william-orlopp-resume.pdf" target="_blank" download="william-orlopp-resume.pdf">william-orlopp-resume.pdf</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutMe;
