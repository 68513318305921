import React from "react";

const Repository = (props : any) => {
    return (
        <div className={"Repository"}>
            <div>
                <h3>Link</h3>
                <a href={props.githubLink} target={"_blank"} rel={"noreferrer"}>GitHub</a>
            </div>

            <div>
                <h3>Commits</h3>
                <p>{props.commits}</p>
            </div>

            <div>
                <h3>Tickets</h3>
                <p>{props.tickets}</p>
            </div>

            <div>
                <h3>Stars</h3>
                <p>{props.stars}</p>
            </div>

            <div>
                <h3>Status</h3>
                <p>{props.status}</p>
            </div>
        </div>
    );
}

export default Repository;
