async function GetGithubInformation(repositoryName : string) {
    let apiLink = `https://api.github.com/repos/beanbeanjuice/${repositoryName}`;
    let apiContributorsLink = `https://api.github.com/repos/beanbeanjuice/${repositoryName}/contributors`

    let commits;
    let open_issues;
    let stars;

    await fetch(apiContributorsLink)
        .then((response) => response.json())
        .then((json) => {
            let commitsObj = json[0];

            if (commitsObj === undefined)
                commits = "Private";
            else
                commits = commitsObj.contributions;
        });

    await fetch(apiLink)
        .then((response) => response.json())
        .then((json) => {
            open_issues = json.open_issues_count;
            stars = json.stargazers_count;

            if (open_issues === undefined) open_issues = "Private";
            if (stars === undefined) stars = "Private";
        });

    return {
        "name": repositoryName,
        "commits": commits,
        "open_issues": open_issues,
        "stars": stars
    }
}

export default GetGithubInformation;
